<template>
  <div class="career-experience">
    <div class="row">
      <div class="col-lg-12">
        <div class="form-group">
          <div class="radio-row">
            <label class="radio-row__title">
              {{ certificate }}
            </label>
            <label
              class="radio-row__label"
              v-for="(item, index) in certificateRadio"
              :key="index"
            >
              <input
                type="radio"
                class="radio-row__input"
                name="certificate"
                ref="inpt"
                v-model="form.IsMinistryofPublicHealthCertificated"
                :value="item.value"
              />
              <span class="radio-row__text">
                {{ $store.state.statics[item.text] }}
              </span>
            </label>
          </div>
        </div>
      </div>

      <div class="col-lg-12" v-show="form.IsMinistryofPublicHealthCertificated">
        <div class="form-group">
          <select class="form-control" v-model="form.JobSpecialtyId">
            <option value="null" selected disabled>
              {{ SpecialtyCertification }}
            </option>
            <option v-for="item in professions" :key="item.id" :value="item.id">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="form-group">
          <input-row
            :label="experiencePeriod"
            v-model="form.Experience"
            type="number"
            @input="$v.form.Experience.$touch()"
            @blur="$v.form.Experience.$touch()"
            :class="{
              invalid: $v.form.Experience.$invalid && $v.form.Experience.$dirty,
            }"
          />
        </div>
      </div>

      <div class="col-lg-12">
        <div class="form-group">
          <input-row
            :label="position"
            v-model="form.Position"
            @input="$v.form.Position.$touch()"
            @blur="$v.form.Position.$touch()"
            :class="{
              invalid: $v.form.Position.$invalid && $v.form.Position.$dirty,
            }"
          />
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <input-row
            :label="organisation"
            v-model="form.OrganisationName"
            @input="$v.form.OrganisationName.$touch()"
            @blur="$v.form.OrganisationName.$touch()"
            :class="{
              invalid:
                $v.form.OrganisationName.$invalid &&
                $v.form.OrganisationName.$dirty,
            }"
          />
        </div>
      </div>
      <div class="col-lg-12 mt-4">
        <div class="row">
          <div class="col-12">
            <div class="checkbox-row">
              <label class="checkbox-row__title">
                {{ WorkDaysLabel }}
              </label>
              <label
                class="checkbox-row__label"
                v-for="(item, index) in WorkDays"
                :key="index"
              >
                <input
                  type="checkbox"
                  class="checkbox-row__input"
                  name="certificate"
                  ref="inpt"
                  v-model="workDays"
                  :value="item.id"
                />
                <span class="checkbox-row__text">
                  {{ item.name }}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 mt-4">
        <div class="row">
          <div class="col-12">
            <label class="label"> {{ workGraphic }} </label>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <input-row
                :label="startDate"
                type="time"
                v-model="form.StartTimeLastWork"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <input-row
                :label="endDate"
                type="time"
                v-model="form.EndTimeLastWork"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-4">
        <div class="accept-terms text-left">
          <input v-model="form.terms" type="checkbox" class="mr-2">
          <terms/>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-12">
        <div class="career-form__buttons">
          <a
            href="#"
            class="button button--outline mr-3"
            @click.prevent="prevPage"
          >
            {{ $store.state.statics.Previous }}
          </a>

          <a
            href="#"
            class="button button--main"
            @click.prevent="changePage"
            :disabled="$v.form.$invalid"
          >
            {{ $store.state.statics.Send }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { minLength, numeric } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import Terms from "@/components/shared/Terms.vue";

export default {
  data() {
    return {
      form: {
        IsMinistryofPublicHealthCertificated: null,
        experiencePeriod: "",
        JobSpecialtyId: null,
        Experience: null,
        Position: "",
        OrganisationName: "",
        StartTimeLastWork: "",
        EndTimeLastWork: "",
        carierWorkDaysOfTheWeeks: [],
        terms: false,
      },
      certificateRadio: [
        { text: "Have", value: true },
        { text: "Havenot", value: false },
      ],
      workDays: [],
    };
  },
  methods: {
    changePage() {
      if (this.workDays.length) {
        this.workDays.sort();
        this.workDays.forEach((i) => {
          let obj = new Object();
          obj.workDaysOfTheWeekId = i;
          this.form.carierWorkDaysOfTheWeeks.push(obj);
        });
      }
      const formData = { ...this.form };
      if (!formData.StartTimeLastWork) {
        delete formData.StartTimeLastWork;
      }if (!formData.EndTimeLastWork) {
        delete formData.EndTimeLastWork;
      }
      this.$emit("experience", formData);
    },
    prevPage() {
      this.$emit("prevPage");
    },
  },
  computed: {
    ...mapState({
      professions: (state) => state.professions,
      certificate: (state) =>
        state.statics.IsMinistryofPublicHealthCertificated,

      profession: (state) => state.statics.ProfessionOfEducation,
      educationPeriod: (state) => state.statics.EducationYear,
      languageSkills: (state) => state.statics.LanguageSkills,

      startDate: (state) => state.statics.StartDate,
      endDate: (state) => state.statics.EndDate,
      workGraphic: (state) => state.statics.WorkGraphic,
      organisation: (state) => state.statics.OrganisationName,
      position: (state) => state.statics.Position,
      experience: (state) => state.statics.WorkExperience,
      experiencePeriod: (state) => state.statics.WorkExperienceDate,
      SpecialtyCertification: (state) => state.statics.SpecialtyCertification,
      WorkDaysLabel: (state) => state.statics.WorkDays,
      WorkDays: (state) => state.weekDays,
    }),
  },
  validations: {
    form: {
      IsMinistryofPublicHealthCertificated: {
        // required,
      },
      Experience: {
        // required,
        numeric,
      },
      Position: {
        // required,
        minLength: minLength(3),
      },
      OrganisationName: {
        // required,
        minLength: minLength(3),
      },
      EndTimeLastWork: {
        // required,
        minLength: minLength(3),
      },
      StartTimeLastWork: {
        minLength: minLength(3),
        // required,
      },

      terms: {
        checked(val) {
          return this.form.terms ? true : val;
        }
      }
    },
  },
  components: {
    Terms
  }
};
</script>
