<template>
  <div class="row w-100">
    <div class="col-lg-6">
      <div class="form-group">
        <input-row :label="language" v-model="value.languageName" />
      </div>
    </div>
    <div class="col-lg-6">
      <select class="form-control" v-model="value.languageLevelId">
        <option value="" selected disabled>
          {{ level }}
        </option>
        <option v-for="item in levels" :key="item.id" :value="item.id">
          {{ item.name }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    value: {
      type: Object,
    },
  },
  computed: {
    ...mapState({
      language: (state) => state.statics.Language,
      level: (state) => state.statics.Level,
      levels: (state) => state.levels,
    }),
  },
};
</script>