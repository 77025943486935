<template>
  <li class="career-steps__item" :class="stepClass">
    <div class="career-steps__no">
      <b-icon :icon="item.icon"></b-icon>
    </div>
    <div class="career-steps__text">
      {{ $store.state.statics[item.text] }}
    </div>
  </li>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    active: {
      type: Number,
      required: true,
    },
  },
  computed: {
    stepClass() {
      if (this.index == this.active) {
        return "career-steps__item--active";
      }
      return "";
    },
  },
};
</script>