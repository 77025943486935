<template>
  <div>
    <div class="row">
      <transition
        enter-active-class="animate__animated animate__fadeIn animate__faster"
        leave-active-class="animate__animated animate__fadeOut animate__faster"
        mode="out-in"
      >
        <div class="col-12" v-if="!formSended" key="form">
          <div class="career__inner">
            <career-steps :active="page" :steps="steps" />
            <career-form
              v-model="page"
              :max="steps.length"
              @send="formSended = true"
            />
          </div>
        </div>
        <div class="col-12" v-else key="success">
          <success-message />
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import CareerSteps from "@/components/career/CareerSteps.vue";
import CareerForm from "@/components/career/work/CareerForm.vue";
import SuccessMessage from "@/components/shared/SuccessMessage.vue";
export default {
  data() {
    return {
      page: 0,
      steps: [
        {
          text: "PrivateInformation",
          icon: "person-fill",
        },
        {
          text: "Education",
          icon: "book-fill",
        },
        {
          text: "ProfessionalActivity",
          icon: "briefcase-fill",
        },
      ],
      formSended: false,
    };
  },
  components: {
    CareerSteps,
    CareerForm,
    SuccessMessage,
  },
};
</script>