<template>
  <div class="career-form">
    <div class="row">
      <div class="col-12">
        <keep-alive>
          <component
              :is="cmps[value]"
              @personal="personal($event)"
              @education="education($event)"
              @experience="experience($event)"
              @prevPage="changePage(-1)"
          ></component>
        </keep-alive>
      </div>
    </div>
  </div>
</template>
<script>
import PersonalInfo from './PersonalInfo.vue';
import Education from './Education.vue';
import Experience from './Experience.vue';

export default {
  data() {
    return {
      cmps: ['PersonalInfo', 'Education', 'Experience'],
      personalForm: {},
      educationForm: {},
      experienceForm: {},
      formSended: true,
    };
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  components: {
    PersonalInfo,
    Education,
    Experience,
  },
  methods: {
    personal(e) {
      this.personalForm = e;
      this.changePage(1);
    },
    education(e) {
      this.educationForm = e;
      this.changePage(1);
    },
    experience(e) {
      this.experienceForm = e;
      if (
          this.experienceForm.StartTimeLastWork
      ) {
        let start = this.experienceForm.StartTimeLastWork?.split(':');
        let s1 = Number(start[0]) * 60 + Number(start[1]);
        this.experienceForm.StartTimeLastWork = s1;

      }
      if (this.experienceForm.EndTimeLastWork) {
        let end = this.experienceForm.EndTimeLastWork?.split(':');
        let e1 = Number(end[0]) * 60 + Number(end[1]);
        this.experienceForm.EndTimeLastWork = e1;
      }


      let p = {
        ...this.personalForm,
        ...this.educationForm,
        ...this.experienceForm,
      };
      this.$store.dispatch('work/sendAppeal', p).then((res) => {
        if (res) {
          this.$emit('send');
        }
      });
    },
    changePage(e) {
      var val = this.value + e;
      if (val >= 0 && val < this.max) {
        this.$emit('input', val);
      }
    },
  },
};
</script>
