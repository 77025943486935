<template>
  <div class="career-education">
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <select class="form-control" v-model="form.InstitutionId">
            <option value="" selected disabled>
              {{ institution }}
            </option>
            <option
              v-for="item in institutions"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <select class="form-control" v-model="form.ProfessionId">
            <option value="" selected disabled>
              {{ profession }}
            </option>
            <option v-for="item in professions" :key="item.id" :value="item.id">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-12 my-3">
        <div class="row">
          <div class="col-12">
            <label class="label"> {{ educationPeriod }} </label>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <input-row
                :label="startDate"
                type="date"
                v-model="form.StartDateHigherEducation"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <input-row
                :label="endDate"
                type="date"
                v-model="form.EndDateHigherEducation"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <label class="label">
          {{ languageSkills }}
        </label>
      </div>

      <div
        class="col-12"
        v-for="(item, index) in form.carierLanguages"
        :key="index"
      >
        <div class="career-form__language-row">
          <education-languages v-model="form.carierLanguages[index]" />
          <a
            href="#"
            class="career-form__remove-language"
            v-if="index !== 0"
            @click.prevent="removeLanguage(index)"
          >
            <b-icon icon="trash"></b-icon>
          </a>
        </div>
      </div>
      <div class="col-12">
        <a href="#" @click.prevent="addLang" class="career-form__add-language">
          {{ $store.state.statics.AddLanguage }}
          <b-icon icon="plus"></b-icon>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="career-form__buttons">
          <a
            href="#"
            class="button button--outline mr-3"
            @click.prevent="prevPage"
          >
            {{ $store.state.statics.Previous }}
          </a>
          <a
            href="#"
            class="button button--main"
            @click.prevent="changePage"
            :disabled="$v.form.$invalid"
          >
            {{ $store.state.statics.Next }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, minLength } from "vuelidate/lib/validators";
import EducationLanguages from "./EducationLanguages.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      form: {
        InstitutionId: "",
        ProfessionId: "",
        carierLanguages: [
          {
            languageLevelId: "",
          },
        ],
        EndDateHigherEducation: "",
        StartDateHigherEducation: "",
      },
    };
  },
  computed: {
    ...mapState({
      institutions: (state) => state.institutions,
      professions: (state) => state.professions,
      institution: (state) => state.statics.HighInstitution,
      profession: (state) => state.statics.ProfessionOfEducation,
      educationPeriod: (state) => state.statics.EducationYear,
      startDate: (state) => state.statics.StartDate,
      endDate: (state) => state.statics.EndDate,
      languageSkills: (state) => state.statics.LanguageSkills,
    }),
  },
  validations: {
    form: {
      InstitutionId: {
        // required,
      },
      ProfessionId: {
        required,
      },
      carierLanguages: {
        $each: {
          languageName: {
            // required,
            minLength: minLength(3),
          },
          languageLevelId: {
            // required,
          },
        },
        // {
        //   languageLevelId: {
        //     required,
        //   },
        //   languageName: {
        //     required,
        //   },
        // },
      },
      EndDateHigherEducation: {
        // required,
        minLength: minLength(8),
      },
      StartDateHigherEducation: {
        // required,
        minLength: minLength(8),
      },
    },
  },
  methods: {
    changePage() {
      const formData = { ...this.form };
      formData.carierLanguages = formData.carierLanguages.filter(
          (lang) => lang.languageLevelId
      );
      if (!formData.InstitutionId) {
        delete formData.InstitutionId;
      }
      if (!formData.EndDateHigherEducation) {
        delete formData.EndDateHigherEducation;
      } if (!formData.StartDateHigherEducation) {
        delete formData.StartDateHigherEducation;
      }
      this.$emit("education", formData);
    },
    prevPage() {
      this.$emit("prevPage");
    },
    addLang() {
      let obj = new Object();
      obj.languageLevelId = "";
      this.form.carierLanguages.push(obj);
    },
    removeLanguage(e) {
      this.form.carierLanguages.splice(e, 1);
    },
  },
  components: {
    EducationLanguages,
  },
};
</script>