<template>
  <ul class="career-steps">
    <career-step
      v-for="(item, index) in steps"
      :key="index"
      :item="item"
      :index="index"
      :active="active"
    />
  </ul>
</template>
<script>
import CareerStep from "./CareerStep.vue";
export default {
  props: {
    active: {
      type: Number,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
  },

  components: {
    CareerStep,
  },
};
</script>