<template>
  <div class="career-personal">
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <input-row
            :label="Name"
            v-model="form.Name"
            @input="$v.form.Name.$touch()"
            @blur="$v.form.Name.$touch()"
            :class="{ invalid: $v.form.Name.$invalid && $v.form.Name.$dirty }"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <input-row
            :label="Surname"
            v-model="form.Surname"
            @input="$v.form.Surname.$touch()"
            @blur="$v.form.Surname.$touch()"
            :class="{
              invalid: $v.form.Surname.$invalid && $v.form.Surname.$dirty,
            }"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <input-row
            :label="Patronymic"
            v-model="form.Patronymic"
            @input="$v.form.Patronymic.$touch()"
            @blur="$v.form.Patronymic.$touch()"
            :class="{
              invalid: $v.form.Patronymic.$invalid && $v.form.Patronymic.$dirty,
            }"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <input-row
            :label="BirthDate"
            type="date"
            v-model="form.BirthDate"
            @input="$v.form.BirthDate.$touch()"
            @blur="$v.form.BirthDate.$touch()"
            :class="{
              invalid: $v.form.BirthDate.$invalid && $v.form.BirthDate.$dirty,
            }"
          />
        </div>
      </div>

      <div class="col-lg-6">
        <div class="form-group">
          <input-row
            :label="Phone"
            v-model="form.Phone"
            @input="$v.form.Phone.$touch()"
            @blur="$v.form.Phone.$touch()"
            :class="{
              invalid: $v.form.Phone.$invalid && $v.form.Phone.$dirty,
            }"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <input-row
            :label="Email"
            v-model="form.Email"
            @input="$v.form.Email.$touch()"
            @blur="$v.form.Email.$touch()"
            :class="{
              invalid: $v.form.Email.$invalid && $v.form.Email.$dirty,
            }"
          />
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <input-row
            :label="residence"
            v-model="form.District"
            @input="$v.form.District.$touch()"
            @blur="$v.form.District.$touch()"
            :class="{
              invalid: $v.form.District.$invalid && $v.form.District.$dirty,
            }"
          />
        </div>
      </div>
      <div class="col-lg-6 mt-3">
        <div class="form-group">
          <div class="radio-row">
            <label class="radio-row__title">
              {{ status }}
            </label>
            <label
              class="radio-row__label"
              v-for="(item, index) in familyStatus"
              :key="index"
            >
              <input
                type="radio"
                class="radio-row__input"
                name="status"
                ref="inpt"
                v-model="form.IsMarried"
                :value="item.value"
              />
              <span class="radio-row__text">
                {{ $store.state.statics[item.text] }}
              </span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mt-3">
        <div class="form-group">
          <div class="radio-row">
            <label class="radio-row__title">
              {{ child }}
            </label>
            <label
              class="radio-row__label"
              v-for="(item, index) in childrenStatus"
              :key="index"
            >
              <input
                type="radio"
                class="radio-row__input"
                name="children"
                ref="inpt"
                v-model="form.IsHaveChild"
                :value="item.value"
              />
              <span class="radio-row__text">
                {{ $store.state.statics[item.text] }}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="career-form__buttons">
          <a
            href="#"
            class="button button--main"
            :disabled="$v.form.$invalid"
            @click.prevent="changePage"
          >
            {{ $store.state.statics.Next }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      selected: "",
      form: {
        Name: "",
        Surname: "",
        Patronymic: "",
        BirthDate: "",
        Email: "",
        Phone: "",
        District: "",
        IsMarried: null,
        IsHaveChild: null,
      },
      familyStatus: [
        { text: "Married", value: true },
        { text: "UnMarried", value: false },
      ],
      childrenStatus: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],

      test: [
        { id: 1, text: "test" },
        { id: 2, text: "test" },
        { id: 3, text: "test" },
      ],
    };
  },
  computed: {
    ...mapState({
      Name: (state) => state.statics.Name,
      Surname: (state) => state.statics.Surname,
      Phone: (state) => state.statics.Phone,
      Patronymic: (state) => state.statics.Patronymic,
      Email: (state) => state.statics.Email,
      BirthDate: (state) => state.statics.BirthDate,
      residence: (state) => state.statics.Residence,
      status: (state) => state.statics.FamilyStatus,
      child: (state) => state.statics.DoYouHaveAChilderen,
    }),
  },
  methods: {
    changePage() {
      const formData = { ...this.form };
      if (!formData.BirthDate) {
        delete formData.BirthDate;
      }
      this.$emit("personal", formData);
    },
  },
  validations: {
    form: {
      Name: {
        required,
        minLength: minLength(3),
      },
      Surname: {
        required,
        minLength: minLength(3),
      },
      Patronymic: {
        // required,
        minLength: minLength(3),
      },
      BirthDate: {
        // required,
      },
      Email: {
        // required,
        minLength: minLength(3),
        email,
      },
      Phone: {
        required,
        minLength: minLength(3),
      },
      District: {
        // required,
        minLength: minLength(3),
      },
      IsMarried: {
        // required,
      },
      IsHaveChild: {
        // required,
      },
    },
  },
};
</script>